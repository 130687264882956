import { MouseEvent } from "react";

import { CarrierMinifiedList } from "components/CarrierList";
import { CarrierEntity } from "components/CarrierList/models";
import CapacityMatchingSegment from "components/CapacityMatchingSegment";

import styles from "pages/Carriers/Carrier.module.scss";
import { CarrierPageFilterProps } from "pages/Carriers/CarrierPageFilter";

interface CarrierQuickViewProps {
  carriers: {
    entities: CarrierEntity[];
    filteredCount: number;
  };
  selectedCarrier: CarrierEntity | null;
  carrierFilter: CarrierPageFilterProps;
  carriersLoading: boolean;
  handlePageSizeChange: ({ value }: { value: number }) => void;
  handleCarrierRowClick: (carrier: CarrierEntity) => void;
  handleOpenEditCarrier: (carrierId: number) => void;
  handlePageChange: ({ activePage }: { activePage: number }) => void;
  handleCarrierMatchClose: () => void;
  handleCarrierUpdate: (carrierId: number) => void;
  dashboardView?: boolean;
}

export const CarrierQuickView = ({
  carriers,
  selectedCarrier,
  carrierFilter,
  carriersLoading,
  handlePageSizeChange,
  handleCarrierRowClick,
  handleOpenEditCarrier,
  handlePageChange,
  handleCarrierMatchClose,
  handleCarrierUpdate,
  dashboardView = false,
}: CarrierQuickViewProps) => {
  return (
    <div className={styles.carrierMinifiedListContainer}>
      <CarrierMinifiedList
        onRowClick={handleCarrierRowClick}
        onEditCarrier={handleOpenEditCarrier}
        carriersLoading={carriersLoading}
        carriers={carriers}
        gridFilter={carrierFilter.pagination}
        handlePageSizeChange={handlePageSizeChange}
        handlePageChange={handlePageChange}
        selectedCarrier={selectedCarrier}
        dashboardView={dashboardView}
      />
      <div
        className={styles.capacityMatchingArea}
        title="capacity-matching-segment"
      >
        <CapacityMatchingSegment
          carrierId={selectedCarrier ? selectedCarrier.id : 0}
          carrierName={selectedCarrier ? selectedCarrier.name : ""}
          onClose={handleCarrierMatchClose}
          onCarrierNoteUpdate={handleCarrierUpdate}
        />
      </div>
    </div>
  );
};
